<template>
  <div class="card relative mb-4" v-bind:class="flipped ? 'flip-container flipped': 'flip-container'">
    <div class="flipper">
      <div class="front rounded-lg overflow-hidden shadow-xl" :style="(front) ? { backgroundImage: 'url(' + front + ')' } : null">
        <div class="name absolute text-xxs font-bold md:text-base lg:text-sm">{{ (data && data.name) ? data.name : 'Nombre y Apellido' }}</div>
        <div class="doc_id absolute text-xxs font-bold md:text-base lg:text-sm">{{ (data && data.doc_id) ? 'DNI: '+data.doc_id : 'DNI: 00000000' }}</div>
        <div class="address_1 absolute text-xxs font-bold md:text-base lg:text-sm">{{ (data && data.address_1) ? data.address_1 : 'Dirección' }}</div>
        <div class="address_2 absolute text-xxs font-bold md:text-base lg:text-sm">{{ (data && data.address_2) ? data.address_2 : 'Ciudad' }}</div>
        <div class="plants_nbr absolute text-xxs font-bold md:text-base lg:text-sm">{{  (data && data.plants_nbr) ? data.plants_nbr : 0 }}</div>
      </div>
      <div class="back rounded-lg overflow-hidden shadow-xl" :style="(front) ? { backgroundImage: 'url(' + back + ')' } : null">
        <div class="name-back absolute text-xxs font-bold md:text-base lg:text-sm">{{ (data && data.name) ? data.name : 'Nombre y Apellido' }}</div>
        <div class="doc_id-back absolute text-xxs font-bold md:text-base lg:text-sm">{{ (data && data.doc_id) ? 'DNI: '+data.doc_id : 'DNI: 00000000' }}</div>
        <div class="emi_date-back absolute text-xxs font-bold md:text-base lg:text-sm">{{ (data && data.emi_date) ? data.emi_date : '00/00/00' }}</div>
        <div class="exp_date-back absolute text-xxs font-bold md:text-base lg:text-sm">{{ (data && data.exp_date) ? data.exp_date : '00/00/00' }}</div>
      </div>
    </div>
  </div>
  <div class="toggle flex bg-secondary-color rounded-full w-1/2 mx-auto">
    <div class="button w-1/2 py-2 px-4 rounded-full text-sm text-center cursor-pointer" :class="(flipped == false) ? 'bg-primary-color text-white' : 'null'" @click="flipped=false">Frente</div>
    <div class="button w-1/2 py-2 px-4 rounded-full text-sm text-center cursor-pointer" :class="(flipped == true) ? 'bg-primary-color text-white' : 'null'" @click="flipped=true">Dorso</div>
  </div>
</template>

<script>
export default {
  name: 'FlipCard',
  data() {
    return {
        flipped: false
    }
  },
  props:{
    front: {
      type: String
    },
    back: {
      type: String
    },
    data:{
      type: Array
    }
  }
}
</script>

<style type='text/css' scoped>
.name{
  top: 30%;
  left: 5%;
}

.doc_id{
  top: 30%;
  left: 73.5%;
}

.address_1{
  top: 44%;
  left: 5%;
}

.address_2{
  top: 50%;
  left: 5%;
}

.plants_nbr{
  top: 58.5%;
  left: 36%;
}

.name-back{
  top: 10%;
  left: 2%;
}

.doc_id-back{
  top: 24%;
  left: 2%;
}

.emi_date-back{
  top: 39%;
  left: 2%;
}

.exp_date-back{
  top: 53%;
  left: 2%;
}

i.frontFlipBtn,
i.backFlipBtn {
    position:absolute; 
    right: 20px; 
    top: 20px;
    color:#FFFFFF;
}
i.backFlipBtn {
    -webkit-transform: rotateY(180deg);
    -moz-transform: rotateY(180deg);
    -o-transform: rotateY(180deg);
    -ms-transform: rotateY(180deg);
    transform: rotateY(180deg);
}
.flip-container {
  -webkit-perspective: 1000;
  -moz-perspective: 1000;
  -o-perspective: 1000;
  perspective: 1000;
}
.flip-container {
  min-height: 120px;
}
.flipper {
  -moz-transform: perspective(1000px);
  -moz-transform-style: preserve-3d;
  position: relative;
}
.front,
.back {
  background-position: center;
  background-size: cover;
  background-color: #dadada;
  padding-top: 62%;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -o-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transition: 0.6s;
  -webkit-transform-style: preserve-3d;
  -moz-transition: 0.6s;
  -moz-transform-style: preserve-3d;
  -o-transition: 0.6s;
  -o-transform-style: preserve-3d;
  -ms-transition: 0.6s;
  -ms-transform-style: preserve-3d;
  transition: 0.6s;
  transform-style: preserve-3d;
  top: 0;
  left: 0;
  width: 100%;
}
.back {
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
  -o-transform: rotateY(180deg);
  -ms-transform: rotateY(180deg);
  transform: rotateY(180deg);
  position: absolute;
}
.flip-container.flipped .back {
  -webkit-transform: rotateY(0deg);
  -moz-transform: rotateY(0deg);
  -o-transform: rotateY(0deg);
  -ms-transform: rotateY(0deg);
  transform: rotateY(0deg);
}
.flip-container.flipped .front {
  -webkit-transform: rotateY(-180deg);
  -moz-transform: rotateY(-180deg);
  -o-transform: rotateY(-180deg);
  -ms-transform: rotateY(-180deg);
  transform: rotateY(-180deg);
}
.front {
  z-index: 2;
}
</style>