
import { defineComponent } from 'vue'
import Button from "@/components/Button.vue"
import FlipCard from '@/components/FlipCard.vue'
import FlipCardNew from '@/components/FlipCardNew.vue'
import Counter from "@/components/Counter.vue"
import Dialog from "@/components/Dialog.vue"
import Toast from "@/components/Toast.vue"

// Utils
import utilities from "../utilities"

export default defineComponent({
  name: "AskCard",
  components: {
    Button,
    FlipCard,
    FlipCardNew,
    Counter,
    Dialog,
    Toast
  },
  data() {
    return {
      local: false,
      token: '',
      step: 1,
      stepClass: '',
      uploadButtontext: 'Subir PDF',
      loading: false,
      loadingText: 'Cargando...',
      cardPreview: false,
      showRemoveDialog: false,
      onConfirmRemove: Function as any,
      onCancelRemove: Function as any,
      currentFile: [] as any[any],
      shippingMethods: [] as any[any],
      pickUpPoints: [] as any[any],
      transferInfo: '',
      selectedShippingMethod: [] as any[any],
      selectedPickUpPoint: [] as any[any],
      basePrice: 0,
      subtotal: 0,
      shipping: 0,
      fields: [] as any[any],
      validated: false,
      paymentMethods: [
        {
          name: 'card',
          label: 'Tarjeta',
          icon: '/img/credit-card-solid.svg',
        },
        {
          name: 'transfer',
          label: 'Transferencia',
          icon: '/img/money-bill-transfer-solid.svg',
        },
        {
          name: 'mp',
          label: 'Mercado Pago',
          icon: '/img/mercado-pago.png',
        }
      ]
    }
  },
  async created() {
    //check auth and get token
    let checkAuth = await utilities.checkAuth('carrito') as any

    this.token = checkAuth.token as any

    //get shipment methods
    let shipmentMethods = await utilities.getItems('shipping_methods?fields=*,fields.*', this.token) as any
    this.shippingMethods = shipmentMethods

    //get pick up points
    let pickUpPoints = await utilities.getItems('shops?filter[pick_up_point]=1', this.token) as any
    this.pickUpPoints = pickUpPoints

    //get config
    let config = await utilities.getItems('config/1?fields=*', this.token) as any
    Promise.resolve(config).then((data) => {

      //transfer info
      this.transferInfo = data.transfer_info

      //base price for credential
      this.basePrice = data.credential_price

      //disable card payments state
      if (data.disable_card_payments) {
        this.paymentMethods.shift()
      }

    })


    //set fields ref table
    this.fields['pick_up_point'] = 'Punto de Retiro'
    this.fields['shipping_first_name'] = 'Nombre'
    this.fields['shipping_last_name'] = 'Apellido'
    this.fields['shipping_id_nbr'] = 'N° de Documento'
    this.fields['shipping_email'] = 'Email'
    this.fields['shipping_area_code'] = 'Codigo de Area'
    this.fields['shipping_phone'] = 'Teléfono'
    this.fields['shipping_street'] = 'Calle'
    this.fields['shipping_street_nbr'] = 'Número/Altura'
    this.fields['shipping_floor'] = 'Piso'
    this.fields['shipping_apt'] = 'Departamento'
    this.fields['shipping_zip_code'] = 'Código Postal'
    this.fields['shipping_city'] = 'Ciudad'
    this.fields['shipping_obs'] = 'Obervaciones'
    this.fields['shipping_ref'] = 'Referencia'

    //update cart total on init
    this.updateCart('items')
  },

  async mounted() {
    let valid = await (await utilities.isValidated()).isValid;
    this.validated = true;
  },
  methods: {
    setShippingMethodsPrice(item: any) {
      this.shippingMethods.forEach((shippingMethod: any, index: number) => {
        if (item.name && item.name.includes('Box') && shippingMethod.price_box > 0) {
          if (!this.shippingMethods[index].selectedPrice || shippingMethod.price_box > this.shippingMethods[index].selectedPrice) {
            this.shippingMethods[index].selectedPrice = shippingMethod.price_box
            this.$store.state.order.has_box = true
          }
        }
        else {
          if (!this.shippingMethods[index].selectedPrice || shippingMethod.price > this.shippingMethods[index].selectedPrice) {
            this.shippingMethods[index].selectedPrice = shippingMethod.price
            this.$store.state.order.has_box = false
          }
        }

        console.log(shippingMethod)
      })
    },
    updateQuantity: function (quantity: number, type: string, fileIndex: number) {
      if (type == 'items') {
        this.$store.state.order.items[fileIndex].quantity = quantity
      }
      else {
        this.$store.state.order.products[fileIndex].quantity = quantity
      }

      this.updateCart('items')
    },
    updateCart: function (type: string, item: any = 0) {

      // update items
      if (type == 'items') {
        this.subtotal = 0
        //credentials sum
        this.$store.state.order.items.forEach((item: any) => {
          this.setShippingMethodsPrice(item)
          this.subtotal += item.item_price * item.quantity
        });
        //products sums
        this.$store.state.order.products.forEach((item: any) => {
          var discount = item.item_discount != null ? item.item_discount : 0;
          console.log("descuento  " + discount);
          console.log("item price: " + item.item_price);
          console.log("item discount: " + item.item_price * discount / 100);
          console.log("item quantity: " + item.quantity)
          this.setShippingMethodsPrice(item);
          this.subtotal += (item.item_price - (item.item_price * (this.validated === true && this.$store.state.flags.descuentoVerificado === true ? discount + 5 : discount) / 100)) * item.quantity;
        });
      }

      // update shipment method
      if (type == 'shipping') {
        this.$store.state.order.shipping_method = item.id
        this.shipping = item.selectedPrice
      }

    },
    removeItem: function (type: string, index: number) {
      this.showRemoveDialog = true

      this.onConfirmRemove = () => {
        if (type == 'items') {
          this.$store.state.order.items.splice(index, 1)
        }
        else {
          this.$store.state.order.products.splice(index, 1)
        }
        this.updateCart('items')
        this.showRemoveDialog = false
      }

      this.onCancelRemove = () => {
        this.showRemoveDialog = false
      }
    },
    setShippingMethod(method: []) {
      //unset previous pick up point
      this.$store.state.order.pick_up_point = null
      this.selectedPickUpPoint = []

      this.selectedShippingMethod = method
      this.updateCart('shipping', method)
      this.nextStep(this.step)
    },
    addShippingData() {
      const form = this.$refs.form as any

      Array.from(form).forEach((input: any) => {
        this.$store.state.order[input.name] = input.value;
      });
    },
    getformattedAddress() {
      let address = ''
      address = this.$store.state.order.shipping_street + ' ' + this.$store.state.order.shipping_street_nbr

      if (this.$store.state.order.shipping_floor || this.$store.state.order.shipping_apt) {
        address += ', '
        if (this.$store.state.order.shipping_floor) {
          address += 'Piso: ' + this.$store.state.order.shipping_floor + ' '
        }

        if (this.$store.state.order.shipping_apt) {
          address += 'Dto: ' + this.$store.state.order.shipping_apt
        }
      }

      address += ', ' + this.$store.state.order.shipping_city + ' (' + this.$store.state.order.shipping_zip_code + ')'

      return address
    },
    setPickUpPoint(id: number) {
      this.pickUpPoints.forEach((pickUpPoint: any[any]) => {
        if (pickUpPoint.id == id) {
          this.selectedPickUpPoint = pickUpPoint
          this.$store.state.order.pick_up_point = id
        }
      })
    },
    setPaymentMethod(value: string) {
      this.$store.state.order.payment_method = value
    },
    async nextStep(step: number) {
      // step 1
      if (step === 1) {
        if (this.$store.state.order.items.length > 0 || this.$store.state.order.products.length > 0) {
          this.step++
        } else {
          (this.$refs.toast as any).show('No aregaste archivos')
        }
      }

      // step 2
      if (step === 2) {
        if (Object.keys(this.selectedShippingMethod).length > 0) {
          this.step++
        } else {
          (this.$refs.toast as any).show('No seleccionaste un método de envío')
        }
      }

      // step 3
      if (step === 3) {

        const form = this.$refs.form as FormData
        const formCheck = utilities.checkForm(form)

        if (formCheck) {
          this.addShippingData()
          this.step++
        } else {
          (this.$refs.toast as any).show('Hay datos incompletos')
        }

      }

      // step 4
      if (step === 4) {

        if (this.$store.state.order.payment_method) {
          this.step++

          //post order
          let createOrders = utilities.createOrder(this.$store.state.order, this.token) as any
          Promise.resolve(createOrders).then((order) => {
            this.step++

            if (order.payment_method == 'card') {

              let geyPayment = utilities.createPaymentHash(order) as any

              Promise.resolve(geyPayment).then((paymentData) => {
                setTimeout(() => {
                  window.location.replace(paymentData.url)
                }, 3000)
              })

            }
            if (order.payment_method == 'mp') {
              console.log(order);
              let getPaymentMP = utilities.paymentMP(
                order.shipping_first_name + " " + order.shipping_last_name, //nombre
                1, //quantity
                order.total, //total price
                order.shipping_track_id,
                order.id) as any

              Promise.resolve(getPaymentMP).then((paymentData) => {
                console.log(paymentData.result.init_point)
                setTimeout(() => {
                  window.location.replace(paymentData.result.init_point)
                }, 3000)
              })
            }
          })
        } else {
          (this.$refs.toast as any).show('Selecciona un método de pago')
        }

      }
    }
  }
})
