
import { defineComponent } from 'vue'

export default defineComponent({
    name: "Counter",
    emits: ['counterUpdated' ,'onZero'],
    data(){
        return{
            counter: 1
        }
    },
    mounted(){
        this.counter = this.$props.quantity
    },
    updated(){
        this.counter = this.$props.quantity
    },
    methods: {
        plus: function(){
            if( this.counter < this.$props.limit){
                this.counter++
                this.$emit('counterUpdated', this.counter)
            }            
        },
        minus: function(){
            if(this.counter >= 2){
                this.counter--
                this.$emit('counterUpdated', this.counter)
            }else{
                this.$emit('onZero')
            }
        }
    },
    props:{
        limit:{
            type: Number,
            default: 1000
        },
        quantity:{
            type: Number,
            default: 1
        }
    }
})
